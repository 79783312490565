<template>
    <div>
        <!-- <router-view></router-view> -->
        <router-view v-slot="{ Component }">
            <keep-alive :exclude="$store.state.keepLiveRoute">
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </div>
</template>
<script>
    export default {
        name:'Index'
    }
</script>
<style scoped>

</style>